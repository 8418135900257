<template>
  <!-- 运营外部班级 -->
  <div class="pageContol listWrap ">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">外部班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                clearable
                v-model="username"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                clearable
                v-model="idCard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                clearable
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <div title="推送状态" class="searchboxItem ci-full">
              <span class="itemLabel">推送状态:</span>
              <el-select clearable size="small" v-model="status">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="df"> -->
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="doExport()">导出</el-button>
            <!-- </div> -->
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              height="100%"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="sourceUserName"
                show-overflow-tooltip
              />
              <el-table-column
                label="学员ID"
                align="left"
                prop="userId"
                show-overflow-tooltip
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="sourceUserIdcard"
                width="150px"
                show-overflow-tooltip
              />

              <el-table-column
                label="手机号"
                align="left"
                prop="sourceUserMobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="实名认证"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="要求总学时"
                align="right"
                prop="totalRequireLessonNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="已完成学时"
                align="right"
                prop="totalStudyLessonNum"
                show-overflow-tooltip
              />
              <el-table-column label="学时学习状态" align="center" minWidth="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.completeState != '40'">{{
                    $setDictionary("LEARNINGSTATE", scope.row.completeState)
                  }}</span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons"
                    placement="top"
                  >
                    <span
                      style="cursor: pointer; display: flex;justify-content: center;"
                      >{{
                        $setDictionary("LEARNINGSTATE", scope.row.completeState)
                      }}</span
                    >
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="课程考试分数"
                align="right"
                prop="paperScore"
                minWidth="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                label="人员类别"
                align="center"
                minWidth="150"
                show-overflow-tooltip
                prop="ccUserType"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("CCUSERTYPE", scope.row.ccUserType) || '--'}}
                </template>
              </el-table-column>
              <el-table-column
                label="推送状态"
                align="left"
                prop="sourceUser"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    $setDictionary("SOURCEUSERSTATE", scope.row.sourceUserState)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="描述"
                align="left"
                prop="sourceUserError"
                show-overflow-tooltip
              ></el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "operate/studentDetailList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      idCard: "",
      username: "",
      mobile: "",
      deletedstate: "true",
      userstate: "",
      projectId: this.$route.query.projectId,
      status: "",
      statusList: [],
    };
  },
  created() {
    this.getStatus();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        mobile: this.mobile || null,
        username: this.username || null,
        idCard: this.idCard || null,
        status: this.status || null,
      };
      this.doFetch({
        url: "/biz/sou/cc/studentPage",
        params,
        pageNum,
      });
    },
    getStatus() {
      const stu = this.$setDictionary("SOURCEUSERSTATE", "list");
      for (let key in stu) {
        this.statusList.push({
          value: key,
          label: stu[key],
        });
      }
    },
    // 导出
    doExport() {
      const params = {
        projectId: this.projectId,
        mobile: this.mobile || null,
        username: this.username || null,
        idCard: this.idCard || null,
        status: this.status || null,
      };
      this.$post("/biz/sou/cc/studentExport", params).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
